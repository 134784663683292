import { request } from "./index";

export const getLeagues = (data) =>
  request("get", "/leagues", {
    params: data,
  });

export const getLeague = (data) => request("get", `/leagues/${data}`);

export const createLeague = (data) => request("post", `/leagues`, data);

export const updateLeague = (data) =>
  request("put", `/leagues/${data.id}`, data.body);
