<template>
  <v-container>
    <div
      class="d-flex align-space-between align-md-center justify-space-between flex-column flex-md-row mb-8"
    >
      <header-view :title="'Ligas'" class="mb-8 mb-md-0" />

      <section class="d-flex justify-end">
        <switch-show-type />

        <v-btn
          color="primary"
          class="black--text"
          depressed
          @click="createLeague()"
        >
          Criar nova
        </v-btn>
      </section>
    </div>

    <filter-input
      class="mb-8"
      :dataFilters="require('@/assets/league/filters/leagues.json')"
    />

    <!-- grid mode -->
    <card-grid
      v-if="$store.getters.viewType === 'grid'"
      :items="_leagues"
      :loading="loading"
      :pagination="pagination"
      @submit="openLeague($event)"
      @update:pagination="handlePagination($event)"
    />

    <!-- list mode -->
    <custom-table
      v-else
      :headers="headers"
      :items="leagues"
      :pagination="pagination"
      :loading="loading"
      @update:pagination="handlePagination($event)"
    >
      <template v-slot:[`item.league`]="{ item }">
        <v-list-item class="px-0">
          <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp" tile>
            <v-img
              :src="item.image || require('@/assets/league/empty-logo.png')"
            />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              <router-link
                class="overtext--text"
                :to="`/league/league-details/${item.id}`"
              >
                {{ item.name }}
              </router-link>
            </v-list-item-title>

            <v-list-item-subtitle>
              {{ handleGame(item.gameId) }} (x{{ item.x }})
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-avatar v-if="$vuetify.breakpoint.xsOnly" tile>
            <v-img
              :src="item.image || require('@/assets/league/empty-logo.png')"
            />
          </v-list-item-avatar>
        </v-list-item>
      </template>

      <template v-slot:[`item.enrollmentsDate`]="{ item }">
        <span class="text-caption">
          De {{ formatDate(item.enrollmentsInitDate) }}
        </span>
        <br />
        <span class="text-caption">
          Até {{ formatDate(item.enrollmentsFinalDate) }}
        </span>
      </template>

      <template v-slot:[`item.tournamentDate`]="{ item }">
        <span class="text-caption">
          De {{ formatDate(item.tournamentInitDate) }}
        </span>
        <br />
        <span class="text-caption">
          Até {{ formatDate(item.tournamentFinalDate) }}
        </span>
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>

      <template v-slot:[`item.updatedAt`]="{ item }">
        {{ formatDate(item.updatedAt) }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        {{ item.isActive ? "Ativa" : "Inativa" }}
      </template>
    </custom-table>

    <CreateLeague ref="createLeague" @success="getData()" />
  </v-container>
</template>

<script>
import { getLeagues } from "@/services/league/leagues.js";
import { getGames } from "@/services/league/games.js";
import { formatDate } from "@/utils";
import CreateLeague from "@/components/league/leagues/CreateLeague.vue";

export default {
  data() {
    return {
      loading: true,

      pagination: {
        page: 0,
        size: 20,
        total: 1,
      },
      headers: [
        {
          text: "Liga",
          value: "league",
        },
        {
          text: "Descrição",
          value: "description",
        },
        {
          text: "Data das inscrições",
          value: "enrollmentsDate",
        },
        {
          text: "Data do torneio",
          value: "tournamentDate",
        },
        {
          text: "Data de criação",
          value: "createdAt",
        },
        {
          text: "Data de atualização",
          value: "updatedAt",
        },
        {
          text: "Status",
          value: "status",
        },
      ],
      leagues: [],
      games: [],
    };
  },

  components: {
    CreateLeague,
  },

  beforeMount() {
    this.getData();
    this.getGames();
  },

  watch: {
    ["$route.query"](value) {
      this.handleQuery(value);
    },
  },

  computed: {
    _leagues() {
      return this.leagues.map((e) => {
        return {
          id: e.id,
          image: e.image || require("@/assets/league/empty-logo.png"),
          title: e.name,
          subtitle: `${this.handleGame(e.gameId)} (x${e.x})`,
          active: e.isActive,
        };
      });
    },
  },

  methods: {
    async getData(filter = {}) {
      try {
        this.loading = true;

        const payload = {
          page: this.pagination.page,
          size: this.pagination.size,
          ...filter,
        };

        await getLeagues(payload).then((res) => {
          this.leagues = res.data;
          this.pagination.total = res.total;
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async getGames() {
      try {
        const payload = {
          page: 0,
          size: 99,
        };

        await getGames(payload).then((res) => {
          this.games = res.data;
        });
      } catch (err) {
        console.log(err);
      }
    },

    handleGame(value) {
      const gameName = this.games.find((e) => e.id === value);
      if (gameName) return gameName.name;

      return "";
    },

    createLeague() {
      this.$refs.createLeague.handleDialog();
    },

    openLeague(event) {
      this.$router.push({ path: `/league/league-details/${event}` });
    },

    handleQuery(value) {
      this.loading = true;
      this.pagination.page = 0;
      this.getData(value);
    },

    handlePagination(event) {
      this.pagination.page = event;
      this.getData();
    },

    formatDate,
  },
};
</script>

<style></style>
