<template>
  <custom-dialog ref="dialog" title="Criar nova liga" @submit="submit()">
    <v-form v-model="valid" ref="form">
      <div @keypress.enter.prevent="submit()">
        <avatar-pick v-model="file" ref="image" />

        <custom-input
          v-model="form.name"
          ref="name"
          label="Nome"
          placeholder="Nome da liga"
        />

        <custom-text-area
          v-model="form.description"
          ref="description"
          label="Descrição"
          placeholder="Digite uma descrição"
        />

        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-md-2">
            <custom-select
              v-model="form.gameId"
              ref="gameId"
              label="Jogo"
              placeholder="Selecione o jogo"
              :items="games"
            />
          </v-col>

          <v-col cols="12" md="6" class="pl-md-2">
            <custom-select
              v-model="form.x"
              ref="x"
              label="Modalidade"
              placeholder="Modalidade de competição"
              :items="modalities"
            />
          </v-col>
        </v-row>

        <custom-input
          v-model="form.tournamentDays"
          ref="tournamentDays"
          label="Detalhamento da data"
          placeholder="Delhamento das datas do evento"
        />

        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-md-2">
            <custom-date-pick
              v-model="form.enrollmentsInitDate"
              ref="enrollmentsInitDate"
              label="Início das inscrições"
            />
          </v-col>

          <v-col cols="12" md="6" class="pl-md-2">
            <custom-date-pick
              v-model="form.enrollmentsFinalDate"
              ref="enrollmentsFinalDate"
              label="Final das inscrições"
            />
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-md-2">
            <custom-date-pick
              v-model="form.tournamentInitDate"
              ref="tournamentInitDate"
              label="Início do evento"
            />
          </v-col>

          <v-col cols="12" md="6" class="pl-md-2">
            <custom-date-pick
              v-model="form.tournamentFinalDate"
              ref="tournamentFinalDate"
              label="Final do evento"
            />
          </v-col>
        </v-row>

        <custom-input
          v-model="form.rulesUrl"
          ref="rulesUrl"
          label="URL do documento das Regras"
          placeholder="https://exemplo.com"
          :customRule="rulesUrl"
        />

        <custom-input
          v-model="form.chalongeUrl"
          ref="chalongeUrl"
          label="URL da tabela"
          placeholder="https://exemplo.com"
          :customRule="rulesUrl"
        />

        <v-switch
          v-model="form.isActive"
          class="my-0 ml-2"
          :label="form.isActive ? 'Ativo' : 'Inativo'"
          inset
        />
      </div>
    </v-form>

    <loader-hover v-if="loading" />
  </custom-dialog>
</template>

<script>
import { createLeague } from "@/services/league/leagues.js";
import { getGames } from "@/services/league/games.js";
import { fileUpload } from "@/services/league/files.js";
import { displayAlert } from "@/utils";

export default {
  data() {
    return {
      loading: false,
      valid: false,
      games: [],
      modalities: [
        {
          text: "1x1",
          value: 1,
        },
        {
          text: "2x2",
          value: 2,
        },
        {
          text: "3x3",
          value: 3,
        },
        {
          text: "4x4",
          value: 4,
        },
        {
          text: "5x5",
          value: 5,
        },
      ],
      file: null,
      form: {
        image: "",
        name: "",
        description: "",
        gameId: "",
        tournamentDays: "",
        tournamentInitDate: "",
        tournamentFinalDate: "",
        enrollmentsInitDate: "",
        enrollmentsFinalDate: "",
        x: null,
        rulesUrl: "",
        chalongeUrl: "",
        isActive: true,
      },
    };
  },

  beforeMount() {
    this.getGames();
  },

  computed: {
    rulesUrl() {
      return [
        (v) =>
          v === "" ||
          v.includes("https://") ||
          (v.includes("http://") && v.includes(".")) ||
          "Informa uma url válida",
      ];
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate() || !this.$refs.image.validate()) return;

      try {
        this.loading = true;

        const formData = new FormData();
        formData.append("file", this.file);

        await fileUpload(formData).then(async (res) => {
          if (res.files.length <= 0) throw new Error("Falha ao salvar imagem");

          const payload = {
            ...this.form,
            image: res.files[0].file_url,
          };

          await createLeague(payload).then(() => {
            this.$emit("success");
            this.close();
          });
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    async getGames() {
      try {
        const payload = {
          page: 0,
          size: 99,
        };

        await getGames(payload).then((res) => {
          this.games = res.data.map((e) => {
            return {
              text: e.name,
              value: e.id,
            };
          });
        });
      } catch (err) {
        console.log(err);
      }
    },

    async handleDialog() {
      await this.$refs.dialog.openDialog();
      this.startForm();
    },

    startForm() {
      this.$refs.image.reset();
      this.$refs.name.handleInput("");
      this.$refs.description.handleInput("");
      this.$refs.gameId.handleInput("");
      this.$refs.tournamentDays.handleInput("");
      this.$refs.tournamentInitDate.handleInput("");
      this.$refs.tournamentFinalDate.handleInput("");
      this.$refs.enrollmentsInitDate.handleInput("");
      this.$refs.enrollmentsFinalDate.handleInput("");
      this.$refs.x.handleInput("");
      this.$refs.rulesUrl.handleInput("");
      this.$refs.chalongeUrl.handleInput("");
      this.form.isActive = true;

      this.$refs.form.resetValidation();
    },

    close() {
      this.$refs.dialog.closeDialog();
    },

    displayAlert,
  },
};
</script>

<style></style>
